import * as React from 'react';

import Layout from '../layout/Layout';

const NotFoundPage = () => {

  return (
    <Layout>
      <h1>Error</h1>
      <p>Page</p>
    </Layout>
  );
};

export default NotFoundPage;
